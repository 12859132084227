import React from 'react'
import ImageGallery from 'react-image-gallery'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Row, imageGalleryCss } from '../components/ui'

import img1 from '../images/csc-1.jpg'
import img2 from '../images/csc-2.jpg'
import img3 from '../images/csc-3.jpg'
import img4 from '../images/csc-4.jpg'
import img5 from '../images/csc-5.jpg'
import img6 from '../images/csc-6.jpg'
import img7 from '../images/csc-7.jpg'

const IndexPage = () => (
  <Layout routePath="custom-shipping-containers">
    <SEO title="Custom Shipping Containers" />

    <Row mt={5} flexDirection={['column', 'row']}>
      <Box width={[1, 1 / 2]} css={imageGalleryCss()}>
        <ImageGallery
          showPlayButton={false}
          showFullscreenButton={false}
          items={[
            { original: img1, thumbnail: img1 },
            { original: img2, thumbnail: img2 },
            { original: img3, thumbnail: img3 },
            { original: img4, thumbnail: img4 },
            { original: img5, thumbnail: img5 },
            { original: img6, thumbnail: img6 },
            { original: img7, thumbnail: img7 },
          ]}
        />
      </Box>

      <Box px={3}></Box>

      <Box width={[1, 1 / 2]} fontSize={14} pt={4}>
        <p>
          Pro Pallet specializes in building custom shipping containers. We have experience building
          shipping containers for livestock, agricultural machinery, vehicles, and construction
          equipment, just to name a few. All containers use heat-treated lumber and are built to
          meet international shipping standards.
        </p>

        <p>
          All shipping containers will be custom designed and built specifically for your needs.
          Everything will be taken into consideration – from the total item’s weight to pressure
          points and reinforcement. With over 40 years combined experience, we can advise you on the
          best way to package and secure your materials for domestic and international shipping.
        </p>

        <p>
          Customers have seen significant reductions in damage rates by switching to our custom
          shipping containers.
        </p>

        <Box textAlign="center" fontSize="90%">
          <em>
            Limited on-site crating available. Please allow time and notice for custom designs.
          </em>
        </Box>
      </Box>
    </Row>
  </Layout>
)

export default IndexPage
